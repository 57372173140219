import { useContext, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { Navigate } from "react-router-dom";
import { Button } from "@fluentui/react-components";
import { handleLogin } from "../utils/auth/AuthActions";
import { ClipLoader } from "react-spinners";
import { useStylesMainPage } from "../styles/MainPageStyle";
import Footer from "../components/footer/Footer";
import { loginRequest, msalInstance } from "../utils/auth/AuthProvider";
import { ThemeContext } from "../utils/GlobalContexts";

const AuthPage = () => {
  const styles = useStylesMainPage();
  const { accounts, inProgress } = useMsal();
  const [showButton, setShowButton] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setShowButton(false);
    let action = () => console.log();
    let timer = setTimeout(() => {
      action();
    }, 2000);
    if (inProgress === InteractionStatus.None) {
      if (accounts.length > 0) action = () => <Navigate to={"/"} />;
      else action = () => setShowButton(true);
    }

    return () => clearTimeout(timer);
  }, [accounts]);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setShowButton(false);
    if (inProgress === InteractionStatus.None)
      if (!isAuthenticated) {
        instance
          .ssoSilent(loginRequest)
          .then((res) => {
            instance.setActiveAccount(res.account);
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              msalInstance.loginRedirect().catch((e) => console.log(e));
            }
          });
      }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <h1 className={styles.loginAppTitle}>Customer Service</h1>
        {showButton ? (
          <Button
            style={{
              backgroundColor: theme.colorBrandForeground1,
              color: "white",
            }}
            size="large"
            onClick={handleLogin}
          >
            BEJELENTKEZÉS
          </Button>
        ) : (
          <ClipLoader size={80} color={theme.colorBrandForeground1} />
        )}
        <Footer />
      </div>
    </div>
  );
};

export default AuthPage;
