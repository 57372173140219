import { ChipContainerProps } from "../../interfaces/ChipContainerProps";
import { useStylesFilterChip } from "../../styles/FilterChipStyle";
import FilterChip from "./FilterChip";

const ChipContainer: React.FC<ChipContainerProps> = ({
  filtersObj,
  setFiltersObj,
}) => {
  const styles = useStylesFilterChip();

  return (
    <div className={styles.container}>
      {filtersObj.accountEnabled !== undefined &&
        filtersObj.accountEnabled !== null && (
          <FilterChip
            setFiltersObj={setFiltersObj}
            name={"accountEnabled"}
            displayName={"Account enabled"}
            value={filtersObj.accountEnabled ? "true" : "false"}
          />
        )}

      {filtersObj.userType !== undefined &&
        filtersObj.userType !== null &&
        filtersObj.userType !== "" && (
          <FilterChip
            setFiltersObj={setFiltersObj}
            name={"userType"}
            displayName={"User type"}
            value={filtersObj.userType}
          />
        )}
    </div>
  );
};

export default ChipContainer;
