import {
  createTableColumn,
  TableCellLayout,
  TableColumnDefinition,
  Theme,
  Tooltip,
} from "@fluentui/react-components";
import { KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto } from "../../CustomerSvcService";
import { formatDate, getEnumTypeText } from "../../utils/HelperFunctions";
import { ListIconMedium } from "../../utils/Icons";
import { NavigateFunction } from "react-router-dom";
import { dateFormatOptions } from "../../utils/GlobalConsts";

export const columnSizingOptionsAdatpontUsers = {
  userId: {
    minWidth: 100,
    defaultWidth: 300,
  },
  role: {
    minWidth: 100,
    defaultWidth: 200,
  },
  createdAt: {
    minWidth: 100,
    defaultWidth: 150,
  },
};

export const getColumnsAdatpontUsers = (theme: Theme) =>
  [
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto>(
      {
        columnId: "userId",
        renderHeaderCell: () => "Id",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip content={item.userId ?? ""} relationship={"description"}>
                <span>{item.userId ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto>(
      {
        columnId: "role",
        renderHeaderCell: () => "Jogosultság",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip content={item.role ?? ""} relationship={"description"}>
                <span>
                  {item.role === 0 ? "Adminisztrátor" : "Felhasználó"}
                </span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto>(
      {
        columnId: "createdAt",
        renderHeaderCell: () => "Létrehozás",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto
        ) => {
          const date = item.createdAt
            ? new Date(item.createdAt).toLocaleDateString(
                "hu-HU",
                dateFormatOptions
              )
            : "-";
          return (
            <TableCellLayout truncate>
              <Tooltip content={date} relationship={"description"}>
                <span>{date}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto>
    ),
  ] as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_TenantUserDto>[];
