import {
  createTableColumn,
  TableCellLayout,
  TableColumnDefinition,
  Theme,
  Tooltip,
} from "@fluentui/react-components";
import { KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto } from "../../CustomerSvcService";
import { ListIconMedium } from "../../utils/Icons";
import { NavigateFunction } from "react-router-dom";

export const columnSizingOptionsAdatpontTenants = {
  name: {
    minWidth: 100,
    defaultWidth: 250,
  },
  id: {
    minWidth: 100,
    defaultWidth: 300,
  },
  companyCode: {
    minWidth: 100,
    defaultWidth: 150,
  },
  taxNumber: {
    minWidth: 100,
    defaultWidth: 150,
  },
  invitations: {
    minWidth: 100,
    defaultWidth: 100,
  },
  companies: {
    minWidth: 100,
    defaultWidth: 100,
  },
  users: {
    minWidth: 100,
    defaultWidth: 100,
  },
};

export const getColumnsAdatpontTenants = (
  theme: Theme,
  navigate: NavigateFunction,
  oid: string
) =>
  [
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>(
      {
        columnId: "name",
        renderHeaderCell: () => "Név",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip content={item.name ?? ""} relationship={"description"}>
                <span>{item.name ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>(
      {
        columnId: "id",
        renderHeaderCell: () => "Id",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip content={item.id ?? ""} relationship={"description"}>
                <span>{item.id ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>(
      {
        columnId: "taxNumber",
        renderHeaderCell: () => "Adószám",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip
                content={item.taxNumber ?? ""}
                relationship={"description"}
              >
                <span>{item.taxNumber ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>(
      {
        columnId: "companyCode",
        renderHeaderCell: () => "Kód",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip
                content={item.companyCode ?? ""}
                relationship={"description"}
              >
                <span>{item.companyCode ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>(
      {
        columnId: "users",
        renderHeaderCell: () => "Felhasználók",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto
        ) => {
          return (
            <TableCellLayout truncate>
              <ListIconMedium
                style={{ cursor: "pointer" }}
                onClick={() => {
                  item.id &&
                    navigate("/adatpont-users", {
                      state: {
                        tenant: item.id,
                        oid: oid,
                      },
                    });
                }}
              />
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>(
      {
        columnId: "invitations",
        renderHeaderCell: () => "Meghívók",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto
        ) => {
          return (
            <TableCellLayout truncate>
              <ListIconMedium
                style={{ cursor: "pointer" }}
                onClick={() => {
                  item.id &&
                    navigate("/adatpont-invitations", {
                      state: {
                        tenant: item.id,
                        oid: oid,
                      },
                    });
                }}
              />
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>(
      {
        columnId: "companies",
        renderHeaderCell: () => "Cégek",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto
        ) => {
          return (
            <TableCellLayout truncate>
              <ListIconMedium
                style={{ cursor: "pointer" }}
                onClick={() => {
                  item.id &&
                    navigate("/adatpont-companies", {
                      state: {
                        tenant: item.id,
                        oid: oid,
                      },
                    });
                }}
              />
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>
    ),
  ] as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_AdminUserTenantDto>[];
