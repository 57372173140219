import {
  Avatar,
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";
import { ProfileInfoBoxProps } from "../..//interfaces/ProfileInfoBoxProps";
import { profileInfoBoxStyles } from "../../styles/ProfileInfoBoxStyle";
import { handleLogout } from "../../utils/auth/AuthActions";

const ProfileInfoBox: React.FC<ProfileInfoBoxProps> = ({
  profileInfo,
  photoUrl,
}) => {
  const styles = profileInfoBoxStyles();
  return (
    <Popover>
      <PopoverTrigger>
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <span className={styles.displayName}>
              {profileInfo?.displayName}
            </span>
            <br />
            <span className={styles.jobTitle}>{profileInfo?.jobTitle}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              size={36}
              image={{
                src: photoUrl ?? "",
              }}
            />
          </div>
        </div>
      </PopoverTrigger>

      <PopoverSurface>
        <Button
          appearance="secondary"
          onClick={handleLogout}
          className={styles.logoutButton}
        >
          Kijelentkezés
        </Button>
      </PopoverSurface>
    </Popover>
  );
};

export default ProfileInfoBox;
