import {
  createTableColumn,
  TableCellLayout,
  TableColumnDefinition,
  Theme,
  Tooltip,
} from "@fluentui/react-components";
import {
  KS_customerservice_Service_Models_UserDto,
  KS_customerservice_Service_Models_UserManagerUserDto,
} from "../../CustomerSvcService";
import { formatDate, getEnumTypeText } from "../../utils/HelperFunctions";

export const columnSizingOptionsUserManagerUsers = {
  name: {
    minWidth: 100,
    defaultWidth: 120,
  },
  companyName: {
    minWidth: 100,
    defaultWidth: 100,
  },
  email: {
    minWidth: 100,
    defaultWidth: 150,
  },
  role: {
    minWidth: 100,
    defaultWidth: 100,
  },
  invitationStatus: {
    minWidth: 100,
    defaultWidth: 150,
  },
  lastLoginDate: {
    minWidth: 100,
    defaultWidth: 150,
  },
  creationDateTime: {
    minWidth: 100,
    defaultWidth: 150,
  },
};

export const getColumnsUserManagerUsers = (theme: Theme) =>
  [
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "name",
      renderHeaderCell: () => "Név",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            <Tooltip
              content={`${item.lastName ?? ""} ${item.firstName ?? ""}`}
              relationship={"description"}
            >
              <span>{`${item.lastName ?? ""} ${item.firstName ?? ""}`}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "email",
      renderHeaderCell: () => "Email",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            <Tooltip content={item.email ?? ""} relationship={"description"}>
              <span>{item.email ?? ""}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "id",
      renderHeaderCell: () => "Id",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            <Tooltip content={item.id ?? ""} relationship={"description"}>
              <span>{item.id ?? ""}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "companyName",
      renderHeaderCell: () => "Cégnév",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            <Tooltip
              content={item.companyName ?? ""}
              relationship={"description"}
            >
              <span>{item.companyName ?? ""}</span>
            </Tooltip>
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "role",
      renderHeaderCell: () => "Szerepkör",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            {getEnumTypeText(item.role) ?? ""}
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "lastLoginDate",
      renderHeaderCell: () => "Utolsó belépés",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            {formatDate(item.lastLoginDate) ?? ""}
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "invitationStatus",
      renderHeaderCell: () => "Státusz",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            {getEnumTypeText(item.invitationStatus) ?? ""}
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "creationDateTime",
      renderHeaderCell: () => "Létrehozás",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            {formatDate(item.creationDateTime)}
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserManagerUserDto>({
      columnId: "deleted",
      renderHeaderCell: () => "Törölve",
      renderCell: (
        item: KS_customerservice_Service_Models_UserManagerUserDto
      ) => {
        return (
          <TableCellLayout truncate>
            {item.deleted ? <span>✅</span> : <span>❌</span>}
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>),
  ] as TableColumnDefinition<KS_customerservice_Service_Models_UserManagerUserDto>[];
