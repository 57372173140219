import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import AuthPage from "./pages/AuthPage";
import { msalInstance } from "./utils/auth/AuthProvider";
import Header from "./components/header/Header";
import Providers from "./providers";

export default function Auth({ children }: { children: React.ReactNode }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Providers>
        <AuthenticatedTemplate>
          <Header />
          {children}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AuthPage />
        </UnauthenticatedTemplate>
      </Providers>
    </MsalProvider>
  );
}
