import { useContext, useEffect } from "react";
import { FilterChipProps } from "../../interfaces/FilterChipProps";
import { useStylesFilterChip } from "../../styles/FilterChipStyle";
import { DismissIcon } from "../../utils/Icons";
import { ThemeContext } from "../../utils/GlobalContexts";

const FilterChip: React.FC<FilterChipProps> = ({
  name,
  displayName,
  value,
  setFiltersObj,
}) => {
  const styles = useStylesFilterChip();
  const { theme } = useContext(ThemeContext);
  const deleteChip = () => {
    setFiltersObj((prev) => ({ ...prev, [name]: undefined }));
  };

  return (
    <div
      className={styles.chip}
      style={{ backgroundColor: theme.colorBrandBackground2 }}
    >
      <span className={styles.name}>{displayName}</span>
      <span className={styles.delimeter}>:</span>
      <span className={styles.value}>{value}</span>
      <DismissIcon className={styles.icon} onClick={deleteChip} />
    </div>
  );
};

export default FilterChip;
