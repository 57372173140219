import {
  DialogActions,
  DialogTrigger,
  Button,
} from "@fluentui/react-components";
import { DialogActionsProps } from "../../interfaces/DialogActionsProps";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";

const DialogActionsComponent: React.FC<DialogActionsProps> = ({
  closeBtnText,
  saveBtnText,
  saveAction,
  isModalLoading,
  disabledSave,
}) => {
  return (
    <DialogActions style={{ width: "445px" }} position="start">
      <DialogTrigger disableButtonEnhancement>
        <Button
          style={{ width: saveAction ? "50%" : "100%" }}
          className="secondaryBtn"
          appearance="secondary"
          disabled={isModalLoading}
        >
          {closeBtnText}
        </Button>
      </DialogTrigger>
      {saveAction && (
        <Button
          className="primaryBtn"
          appearance="primary"
          style={{ width: "50%" }}
          onClick={saveAction}
          disabled={disabledSave === true || isModalLoading}
        >
          {isModalLoading ? (
            <ClipLoader size={16} color="#FFFFFF" />
          ) : (
            saveBtnText
          )}
        </Button>
      )}
    </DialogActions>
  );
};

export default DialogActionsComponent;
