import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesFilterChip = makeStyles({
  container: { display: "inline-flex" },
  chip: {
    display: "inline-flex",
    ...shorthands.padding("4px", "8px"),
    ...shorthands.borderRadius("15px"),
    marginRight: "5px",
  },
  name: { fontSize: "13px" },
  value: { fontWeight: 700, fontSize: "13px" },
  icon: { marginTop: "3px", marginLeft: "5px", cursor: "pointer" },
  delimeter: {
    ...shorthands.margin("0px", "5px", "0px", "5px"),
    fontSize: "13px",
  },
});
