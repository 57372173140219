import {
  createTableColumn,
  TableCellLayout,
  TableColumnDefinition,
  Theme,
  Tooltip,
} from "@fluentui/react-components";
import { KS_customerservice_Service_Models_AdminUserDtos_CompanyDto } from "../../CustomerSvcService";
import { dateFormatOptions } from "../../utils/GlobalConsts";

export const columnSizingOptionsAdatpontCompanies = {
  name: {
    minWidth: 100,
    defaultWidth: 300,
  },
  taxNumber: {
    minWidth: 100,
    defaultWidth: 200,
  },
  databaseGuid: {
    minWidth: 100,
    defaultWidth: 300,
  },
  isDeleted: {
    minWidth: 100,
    defaultWidth: 100,
  },
  lastSync: {
    minWidth: 100,
  },
};

export const getColumnsAdatpontCompanies = (theme: Theme) =>
  [
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>(
      {
        columnId: "name",
        renderHeaderCell: () => "Név",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_CompanyDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip
                content={"DB-ben lévő alapértelmezett cégprofil neve"}
                relationship={"description"}
              >
                <span>{item.name ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>(
      {
        columnId: "taxNumber",
        renderHeaderCell: () => "Adószám",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_CompanyDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip
                content={item.taxNumber ?? ""}
                relationship={"description"}
              >
                <span>{item.taxNumber ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>(
      {
        columnId: "databaseGuid",
        renderHeaderCell: () => "Adatbázis GUID",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_CompanyDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip
                content={item.databaseGuid ?? ""}
                relationship={"description"}
              >
                <span>{item.databaseGuid ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>(
      {
        columnId: "isDeleted",
        renderHeaderCell: () => "Aktív",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_CompanyDto
        ) => {
          return item.isDeleted ? <span>❌</span> : <span>✅</span>;
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>(
      {
        columnId: "lastSync",
        renderHeaderCell: () => "Utolsó adatfeladás",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_CompanyDto
        ) => {
          const date = item.lastSync
            ? new Date(item.lastSync).toLocaleDateString(
                "hu-HU",
                dateFormatOptions
              )
            : "-";
          return (
            <TableCellLayout truncate>
              <Tooltip content={date} relationship={"description"}>
                <span>{date}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>
    ),
  ] as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_CompanyDto>[];
