import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesTable = makeStyles({
  selectedRow: {
    backgroundColor: "#5b5ec72f",
    ":hover": { backgroundColor: "#5b5ec754" },
  },
  lastEditedRow: {
    backgroundColor: "rgba(116, 237, 126, 0.5)",
  },
  pastValidDate: {
    backgroundColor: "#fc18270c",
    ":hover": { backgroundColor: "#fc182726" },
  },
  row: {
    cursor: "pointer",
    ":nth-child(even)": {
      backgroundColor: "transparent",
    },
    backgroundColor: "rgba(117, 118, 159, 0.15)",
    ":hover": { backgroundColor: "#787bcc27" },
  },

  headerCell: {
    fontWeight: 700,
  },
  bodyCell: {
    wordBreak: "break-all",
  },
  spinner: { ...shorthands.padding("25px") },
  warningText: {
    textAlign: "center",
    width: "100%",
    ...shorthands.padding("25px"),
  },
  tableHeader: {
    // backgroundColor: theme,
    zIndex: 1,
    paddingRight: "7px",
    ...shorthands.borderRadius("5px", "0px", "0px", "0px"),
  },
});
