import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesHeader = makeStyles({
  container: {
    zIndex: 10,
    fontFamily: "'Days One', sans-serif",
    ...shorthands.padding("5px"),
    width: "100%",
    maxWidth: "100%",
    height: "48px",
    boxShadow:
      "0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 6px -1px rgba(0, 0, 0, 0.07), 0 2px 4px 0 rgba(0, 0, 0, 0.07)",
    paddingInlineStart: "10px",
    display: "inline-flex",
    WebkitAlignItems: "center",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    lineHeight: "64px",
    letterSpacing: "-0.18px",
  },
  title: {
    fontSize: "24px",
    letterSpacing: "-0.18px",
    marginLeft: "5px",
    whiteSpace: "nowrap",
  },
});
