import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CUSTOMERSERVICE_UI_CLIENT_ID as string,
    authority: `https://login.microsoftonline.com/b1b8b4a0-3584-40fd-9b34-fda7fe81ffb9`,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  },
};

export const loginRequest = {
  scopes: [
    ((process.env.REACT_APP_CUSTOMERSERVICE_API_AUDIENCE as string) +
      "/" +
      process.env.REACT_APP_CUSTOMERSERVICE_SCOPE_WRITE) as string,
  ],
};

export const graphAPIRequest = {
  scopes: ["https://graph.microsoft.com/User.Read"],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };
