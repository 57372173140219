import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useStylesMainPage } from "../styles/MainPageStyle";
import { RefreshContext, ThemeContext } from "../utils/GlobalContexts";
import DialogComponent from "../components/dialog/DialogComponent";
import TableComponent from "../components/table/TableComponent";
import {
  CustomerServiceService,
  Microsoft_Graph_Models_UserCollectionResponse,
  Microsoft_Graph_Models_User,
  KS_customerservice_Service_Models_UserDto,
} from "../CustomerSvcService";
import {
  Button,
  Input,
  InputOnChangeData,
  Label,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Switch,
  SwitchOnChangeData,
  useId,
} from "@fluentui/react-components";
import { FilterObj } from "../models/FilterObj";
import {
  SearchIcon,
  EditIcon,
  DeleteIcon,
  DismissIcon,
  DismissIconMedium,
} from "../utils/Icons";
import { ReactComponent as DeleteSVG } from "../assets/delete.svg";
import { ReactComponent as CompleteSVG } from "../assets/complete.svg";
import { ReactComponent as WarningSVG } from "../assets/warning.svg";
import { ReactComponent as UnsuccessfulSVG } from "../assets/unsuccesful.svg";
import { useStylesDialog } from "../styles/FormDialogStyle";
import { NOTIFICATION_DIALOG, TABLE } from "../utils/Enums";
import _ from "lodash";
import { isUserUnchanged } from "../utils/HelperFunctions";
import FilterPopover from "../components/filters/FilterPopover";
import ChipContainer from "../components/filters/ChipContainer";
import { useNavigate } from "react-router-dom";

export default function UserListPage() {
  const styles = useStylesMainPage();
  const stylesDialog = useStylesDialog();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openCompaniesDialog, setOpenCompaniesDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [notificationDialogText, setNotificationDialogText] = useState("");
  const [notificationDialogTitle, setNotificationDialogTitle] = useState("");
  const [notificationDialogType, setNotificationDialogType] = useState(
    NOTIFICATION_DIALOG.WARNING
  );
  const [data, setData] = useState<
    Microsoft_Graph_Models_UserCollectionResponse | undefined | null
  >(null);
  const [users, setUsers] = useState<
    KS_customerservice_Service_Models_UserDto[] | undefined | null
  >(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const { refresh, setRefresh } = useContext(RefreshContext);
  const [selectedUser, setSelectedUser] =
    useState<Microsoft_Graph_Models_User | null>(null);
  const [unsavedUser, setUnsavedUser] =
    useState<Microsoft_Graph_Models_User | null>(null);
  const [filtersObj, setFiltersObj] = useState({} as FilterObj);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const [sortedItems, setSortedItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(30);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setSortedItems(
      filteredItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
  }, [filteredItems, currentPage, itemsPerPage]);

  useEffect(() => {
    if (!users) getAllUsers();
    else filterUsers();
    setSelectedUser(null);
  }, [filtersObj]);

  const filterUsers = async () => {
    if (users) {
      const filtered = users.filter((user) => {
        const { displayName, name, userName, accountEnabled, userType } = user;
        const {
          phrase,
          accountEnabled: filterAccountEnabled,
          userType: filterUserType,
        } = filtersObj;

        const phraseMatch =
          !phrase ||
          (displayName &&
            displayName.toLowerCase().includes(phrase.toLowerCase())) ||
          (name && name.toLowerCase().includes(phrase.toLowerCase())) ||
          (userName && userName.toLowerCase().includes(phrase.toLowerCase()));

        const accountEnabledMatch =
          filterAccountEnabled === undefined ||
          accountEnabled === filterAccountEnabled;

        const userTypeMatch =
          filterUserType === undefined || userType === filterUserType;

        return phraseMatch && accountEnabledMatch && userTypeMatch;
      });

      setFilteredItems(filtered);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (refresh) {
      setIsLoading(true);
      getAllUsers();
      setRefresh(false);
    }
  }, [refresh]);

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleDeleteUserClick = () => {
    setOpenConfirmDialog(true);
  };

  const handleEditUserClick = () => {
    setUnsavedUser(_.cloneDeep(selectedUser));
    setOpenEditUserDialog(true);
  };

  const handleNotificationDialogOpen = (
    title: string,
    text: string,
    type: NOTIFICATION_DIALOG
  ) => {
    setNotificationDialogText(text);
    setNotificationDialogTitle(title);
    setNotificationDialogType(type);
    setOpenNotificationDialog(true);
  };

  const handleEditUserAction = async () => {
    setIsModalLoading(true);
    if (unsavedUser)
      await CustomerServiceService.patchApiCustomerService(unsavedUser)
        .then(() => {
          setIsModalLoading(false);
          setRefresh(true);
          handleEditUserDialogClose();
          handleNotificationDialogOpen(
            "Felhasználó szerkesztése",
            "A felhasználó szerkesztése sikeres volt.",
            NOTIFICATION_DIALOG.SUCCESS
          );
        })
        .catch((e) => {
          setIsModalLoading(false);
          handleNotificationDialogOpen(
            "Felhasználó szerkesztése",
            "A felhasználó szerkesztése nem sikerült.",
            NOTIFICATION_DIALOG.FAIL
          );
        });
  };

  const handleDeleteUserAction = async () => {
    setIsModalLoading(true);
    if (selectedUser?.id) {
      await CustomerServiceService.deleteApiCustomerService(selectedUser.id)
        .then(() => {
          setIsModalLoading(false);
          setRefresh(true);
          handleConfirmDialogClose();
          handleNotificationDialogOpen(
            "Felhasználó eltávolítása",
            "A felhasználó eltávolítása sikeres volt.",
            NOTIFICATION_DIALOG.SUCCESS
          );
        })
        .catch((e) => {
          setIsModalLoading(false);
          handleConfirmDialogClose();
          handleNotificationDialogOpen(
            "Felhasználó eltávolítása",
            "A felhasználó eltávolítása nem sikerült.",
            NOTIFICATION_DIALOG.FAIL
          );
        });
    }
  };

  const handleSaveDialogAction = () => {};

  const handleOpenCompaniesDialog = async (oid: string | null | undefined) => {
    if (oid)
      await CustomerServiceService.getApiCustomerServiceUsermanagerCompanies(
        oid
      )
        .then((res) => {
          if (res && res[0]) {
            const uri = `/company-data/${oid}`;
            // window?.open(uri, "_blank")?.focus();
            navigate(uri);
          } else
            handleNotificationDialogOpen(
              `${selectedUser?.displayName ?? "unknown"} cégei`,
              "Nincsenek megjeleníthető cégek.",
              NOTIFICATION_DIALOG.WARNING
            );
        })
        .catch((e) => {
          handleNotificationDialogOpen(
            `${selectedUser?.displayName ?? "unknown"} cégei`,
            "A felhasználóhoz tartozó cégek betöltése nem sikerült.",
            NOTIFICATION_DIALOG.FAIL
          );
        });
  };

  const hasActiveFilters = () =>
    filtersObj &&
    (filtersObj.accountEnabled !== undefined ||
      (filtersObj.userType && filtersObj.userType !== ""));

  const resetFilters = () => {
    setFiltersObj((prev) => ({
      ...prev,
      userType: undefined,
      externalUsers: undefined,
      accountEnabled: undefined,
    }));
  };

  const editUserProperty = (
    ev: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => {
    setUnsavedUser((prev: Microsoft_Graph_Models_User | null) => ({
      ...prev,
      [ev.target.name]: data.value,
    }));
  };

  const editUserPropertySwitch = (
    ev: ChangeEvent<HTMLInputElement>,
    data: SwitchOnChangeData
  ) => {
    setUnsavedUser((prev: Microsoft_Graph_Models_User | null) => ({
      ...prev,
      [ev.target.name]: data.checked,
    }));
  };

  const handleEditUserDialogClose = () => {
    setOpenEditUserDialog(false);
    setUnsavedUser(null);
  };

  const getAllUsers = async () => {
    await CustomerServiceService.getApiCustomerServiceUsers()
      .then((res) => {
        setUsers(res);
        setFilteredItems(res);
        setIsLoading(false);
      })
      .catch((e) => setIsLoading(false));
  };

  const dismissSearch = (event: any) => {
    if (filtersObj?.phrase)
      setFiltersObj((prev) => ({
        ...prev,
        phrase: undefined,
      }));
  };

  return (
    <div className={styles.main}>
      <div className={styles.buttons}>
        <div style={{ display: "inline-flex" }}>
          <Input
            onChange={(e: any, data: any) => {
              setFiltersObj((prev: FilterObj) => ({
                ...prev,
                phrase: data.value,
              }));
            }}
            contentBefore={<SearchIcon />}
            contentAfter={
              <DismissIcon
                style={{ cursor: "pointer" }}
                onClick={dismissSearch}
              />
            }
            id={useId("content-before")}
            size="small"
            placeholder="Keresés..."
            style={{
              margin: "15px 5px",
              marginTop: "15px",
              width: "250px",
              height: "32px",
            }}
            value={filtersObj.phrase ?? ""}
          />
          <span
            style={{
              margin: "15px 5px",
              marginTop: "15px",
              height: "36px",
            }}
          >
            <ChipContainer
              filtersObj={filtersObj}
              setFiltersObj={setFiltersObj}
            />
            <FilterPopover
              filtersObj={filtersObj}
              setFiltersObj={setFiltersObj}
            />
            <DismissIconMedium
              style={{
                marginBottom: "-7px",
                cursor: hasActiveFilters() ? "pointer" : "not-allowed",
                color: hasActiveFilters()
                  ? theme.colorBrandForeground1
                  : "gray",
              }}
              onClick={resetFilters}
            />
          </span>
        </div>
        <div>
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button
                className={styles.button}
                appearance={selectedUser?.id ? "primary" : "subtle"}
              >
                Műveletek
              </Button>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem
                  icon={<EditIcon />}
                  disabled={!selectedUser?.id}
                  onClick={handleEditUserClick}
                >
                  {"Szerkesztés"}
                </MenuItem>
                <MenuItem
                  icon={<DeleteIcon />}
                  disabled={!selectedUser?.id}
                  onClick={handleDeleteUserClick}
                >
                  {"Törlés"}
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      </div>
      <TableComponent
        type={TABLE.USERS}
        handleRowAction={handleOpenCompaniesDialog}
        data={sortedItems}
        isLoading={isLoading}
        isLoadingMoreData={isLoadingMoreData}
        selectedDto={selectedUser}
        setSelectedDto={setSelectedUser}
        nextLink={data?.odataNextLink}
      />
      <Pagination
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalItems={filteredItems.length}
        onPageChange={handlePageChange}
      />
      <DialogComponent
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        handleSave={handleDeleteUserAction}
        handleClose={handleConfirmDialogClose}
        okBtnText="Eltávolítás"
        closeBtnText="Mégse"
        title="Felhasználó eltávolítása"
      >
        <div style={{ marginBottom: "10px" }}>
          <DeleteSVG
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              marginBottom: "10px",
            }}
          />
          <div className={stylesDialog.text}>
            <span style={{ fontWeight: 700, marginRight: 5 }}>
              {selectedUser?.displayName ?? ""}
            </span>
            <span>felhasználó</span>
            <div style={{ marginTop: 10 }}>
              Biztos vagy benne, hogy el szeretnéd távolítani a következő
              felhasználót?
            </div>
          </div>
        </div>
      </DialogComponent>

      <DialogComponent
        open={openNotificationDialog}
        setOpen={setOpenNotificationDialog}
        handleClose={() => setOpenNotificationDialog(false)}
        closeBtnText="Ok"
        title={notificationDialogTitle}
      >
        <div style={{ marginBottom: "10px" }}>
          {notificationDialogType === NOTIFICATION_DIALOG.SUCCESS ? (
            <CompleteSVG
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            />
          ) : notificationDialogType === NOTIFICATION_DIALOG.FAIL ? (
            <UnsuccessfulSVG
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            />
          ) : (
            <WarningSVG
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            />
          )}
          <div className={stylesDialog.text}>{notificationDialogText}</div>
        </div>
      </DialogComponent>

      <DialogComponent
        disabledSave={isUserUnchanged(selectedUser, unsavedUser)}
        isModalLoading={isModalLoading}
        open={openEditUserDialog}
        setOpen={setOpenEditUserDialog}
        handleClose={handleEditUserDialogClose}
        closeBtnText="Mégse"
        handleSave={handleEditUserAction}
        okBtnText="Szerkesztés"
        title={"Felhasználó szerkesztése"}
      >
        <div style={{ marginBottom: "10px" }}>
          <div className={stylesDialog.text}>
            <div style={{ marginTop: 10, width: "100%" }}>
              <Label
                style={{ display: "block", float: "left", fontSize: "13px" }}
              >
                {"Megjelenített név"}
              </Label>
              <Input
                style={{ width: "100%" }}
                name="displayName"
                value={unsavedUser?.displayName ?? ""}
                onChange={editUserProperty}
              />
            </div>
            <div style={{ marginTop: 10, width: "100%" }}>
              <Label
                style={{ display: "block", float: "left", fontSize: "13px" }}
              >
                {"Előnév"}
              </Label>
              <Input
                style={{ width: "100%" }}
                name="surname"
                value={unsavedUser?.surname ?? ""}
                onChange={editUserProperty}
              />
            </div>
            <div style={{ marginTop: 10, width: "100%" }}>
              <Label
                style={{ display: "block", float: "left", fontSize: "13px" }}
              >
                {"Utónév"}
              </Label>
              <Input
                style={{ width: "100%" }}
                name="givenName"
                value={unsavedUser?.givenName ?? ""}
                onChange={editUserProperty}
              />
            </div>
            <div style={{ marginTop: 10, width: "100%", display: "flex" }}>
              <Label
                style={{
                  display: "block",
                  float: "left",
                  fontSize: "13px",
                  marginTop: "7px",
                }}
              >
                {"Account enabled"}
              </Label>
              <Switch
                style={{ textAlign: "left", justifySelf: "flex-start" }}
                name="accountEnabled"
                checked={unsavedUser?.accountEnabled ?? false}
                onChange={editUserPropertySwitch}
              />
            </div>
          </div>
        </div>
      </DialogComponent>
    </div>
  );
}

const Pagination: React.FC<{
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (pageNumber: number) => void;
}> = ({ currentPage, itemsPerPage, totalItems, onPageChange }) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: 50,
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Button onClick={handlePrevPage} disabled={currentPage === 1}>
        Előző
      </Button>
      <span style={{ marginLeft: "10px", marginRight: "10px" }}>
        {currentPage} / {Math.ceil(totalItems / itemsPerPage)}
      </span>
      <Button
        onClick={handleNextPage}
        disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
      >
        Következő
      </Button>
    </div>
  );
};
