import {
  bundleIcon,
  Add16Regular,
  Add16Filled,
  ArrowStepBack16Filled,
  ArrowStepBack16Regular,
  Open20Filled,
  Open20Regular,
  ArrowReset20Filled,
  ArrowReset20Regular,
  Search16Regular,
  Search16Filled,
  Link20Regular,
  Link20Filled,
  CopyFilled,
  CopyRegular,
  DeleteFilled,
  DeleteRegular,
  DismissFilled,
  DismissRegular,
  Dismiss24Filled,
  Dismiss24Regular,
  EditFilled,
  EditRegular,
  List24Filled,
  List24Regular,
} from "@fluentui/react-icons";

export const AddIcon = bundleIcon(Add16Filled, Add16Regular);
export const LinkIcon = bundleIcon(Open20Filled, Open20Regular);
export const CopyIcon = bundleIcon(Link20Filled, Link20Regular);
export const SearchIcon = bundleIcon(Search16Filled, Search16Regular);
export const BackIcon = bundleIcon(
  ArrowStepBack16Filled,
  ArrowStepBack16Regular
);
export const ResetIcon = bundleIcon(ArrowReset20Filled, ArrowReset20Regular);
export const CloneIcon = bundleIcon(CopyFilled, CopyRegular);
export const EditIcon = bundleIcon(EditFilled, EditRegular);
export const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
export const DismissIcon = bundleIcon(DismissFilled, DismissRegular);
export const DismissIconMedium = bundleIcon(Dismiss24Filled, Dismiss24Regular);
export const ListIconMedium = bundleIcon(List24Filled, List24Regular);
