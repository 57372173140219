import { useEffect, useState } from "react";
import { useStylesMainPage } from "../styles/MainPageStyle";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomerServiceService,
  KS_customerservice_Service_Models_UserDto,
} from "../CustomerSvcService";
import TableComponent from "../components/table/TableComponent";
import { TABLE } from "../utils/Enums";
import { Button } from "@fluentui/react-components";

export default function UsersByDbPage() {
  const styles = useStylesMainPage();
  const { id } = useParams();
  const [users, setUsers] = useState<
    null | KS_customerservice_Service_Models_UserDto[]
  >();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) getCompanyData();
  }, [id]);

  const getCompanyData = async () => {
    await CustomerServiceService.getApiCustomerServiceUsermanagerUsers(id)
      .then((res) => {
        if (res && res[0]) {
          setUsers(res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className={styles.main}>
      <div className={styles.backButton}>
        <Button onClick={navigateBack}>Vissza</Button>
        <div
          className={styles.pageText}
        >{`${id} guidhoz tartozó felhasználók`}</div>
      </div>
      <TableComponent
        type={TABLE.USERMANAGER_USER}
        data={users}
        isLoading={isLoading}
      />
    </div>
  );
}
