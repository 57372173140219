import { useStylesHeader } from "../../styles/HeaderStyle";

import { useContext, useEffect, useState } from "react";

import ProfileInfoBox from "../profile-box/ProfileInfoBox";
import { ProfileInfo } from "../../models/ProfileInfo";
import {
  acquireGraphApiToken,
  handleGraphApiLogin,
} from "../../utils/auth/AuthActions";
import {
  retrieveProfilePhoto,
  retrieveProfileInfo,
} from "../../utils/HelperFunctions";
import { ReactComponent as Kslogo } from "../../assets/KS_logo.svg";
import { Title3 } from "@fluentui/react-components";
import { ThemeContext } from "../../utils/GlobalContexts";
import { darkTheme, lightTheme } from "../../utils/FluentThemeConstructor";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const styles = useStylesHeader();
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [profileInfo, setProfileInfo] = useState<ProfileInfo | null>(null);
  const { theme, setTheme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const navigateToMainPage = () => {
    navigate("/");
  };

  useEffect(() => {
    const fetchPhoto = async () => {
      let token = await acquireGraphApiToken();
      try {
        if (token === null) {
          await handleGraphApiLogin().then(async () => {
            token = await acquireGraphApiToken();
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        const imageUrl = await retrieveProfilePhoto(token);
        const profileinfo = await retrieveProfileInfo(token);
        setProfileInfo(profileinfo as any);
        setPhotoUrl(imageUrl);
      }
    };

    fetchPhoto();
  }, []);

  const toggleTheme = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  return (
    <header className={styles.container}>
      <div className="container" style={{}}>
        <input type="checkbox" id="theme-toggle" />
        <label htmlFor="theme-toggle" onClick={toggleTheme} />
      </div>
      <Kslogo
        onClick={navigateToMainPage}
        style={{ height: "34px", cursor: "pointer" }}
      />
      <Title3 onClick={navigateToMainPage} style={{ cursor: "pointer" }}>
        Customer Service
      </Title3>
      <ProfileInfoBox profileInfo={profileInfo} photoUrl={photoUrl} />
    </header>
  );
};

export default Header;
