import { useEffect, useState } from "react";
import {
  FluentProvider,
  RendererProvider,
  SSRProvider,
  Toaster,
  createDOMRenderer,
  useId,
  webLightTheme,
} from "@fluentui/react-components";
import {
  DeviceContext,
  DeviceProps,
  RefreshContext,
  RefreshProps,
  ThemeContext,
  ThemeProps,
  ToasterContext,
} from "./utils/GlobalContexts";
import { acquireToken } from "./utils/auth/AuthActions";
import {
  blueTheme,
  darkTheme,
  lightTheme,
} from "./utils/FluentThemeConstructor";
import { OpenAPI } from "./CustomerSvcService/core/OpenAPI";

const renderer = createDOMRenderer();

export function Providers({ children }: any) {
  OpenAPI.TOKEN = async () => (await acquireToken()) as any;
  OpenAPI.BASE = process.env.REACT_APP_CUSTOMERSERVICE_API_URI as string;

  const toasterId = useId("toaster");
  const [hasMounted, setHasMounted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    setHasMounted(true);

    // lightTheme.colorBrandForeground1 = blueTheme[70];
    // lightTheme.colorBrandForeground2 = blueTheme[60];
    // darkTheme.colorBrandForeground1 = blueTheme[100];
    // darkTheme.colorBrandForeground2 = blueTheme[110];
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <RendererProvider renderer={renderer || createDOMRenderer()}>
      <SSRProvider>
        <FluentProvider theme={theme}>
          <div className="App">
            <RefreshContext.Provider
              value={{ refresh, setRefresh } as RefreshProps}
            >
              <ThemeContext.Provider value={{ theme, setTheme } as ThemeProps}>
                {children}
              </ThemeContext.Provider>
            </RefreshContext.Provider>
          </div>
        </FluentProvider>
      </SSRProvider>
    </RendererProvider>
  );
}

export default Providers;
