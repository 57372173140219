import {
  Button,
  Field,
  Label,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Switch,
} from "@fluentui/react-components";
import { FilterPopoverProps } from "../../interfaces/FilterPopoverProps";
import { FilterObj } from "../../models/FilterObj";

const FilterPopover: React.FC<FilterPopoverProps> = ({
  filtersObj,
  setFiltersObj,
}) => {
  const isExternalUserSwitchDisabled = () => {
    if (
      (filtersObj.phrase !== undefined &&
        filtersObj.phrase !== null &&
        filtersObj.phrase !== "") ||
      (filtersObj.accountEnabled !== undefined &&
        filtersObj.accountEnabled !== null)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Popover withArrow>
      <PopoverTrigger>
        <Button appearance="primary" style={{ marginRight: 10 }}>
          Szűrőfeltételek
        </Button>
      </PopoverTrigger>

      <PopoverSurface>
        <Label size="small" style={{ display: "block" }}>
          Account enabled
        </Label>
        <Switch
          checked={filtersObj?.accountEnabled ?? false}
          onChange={(e: any, data: any) => {
            setFiltersObj((prev: FilterObj) => ({
              ...prev,
              accountEnabled: data.checked,
            }));
          }}
        />

        <Field label="User type" size="small">
          <RadioGroup
            value={filtersObj?.userType ?? ""}
            onChange={(_, data) =>
              setFiltersObj((prev: FilterObj) => ({
                ...prev,
                userType: data.value,
              }))
            }
          >
            <Radio value="Guest" label="Guest" />
            <Radio value="Member" label="Member" />
          </RadioGroup>
        </Field>
      </PopoverSurface>
    </Popover>
  );
};

export default FilterPopover;
