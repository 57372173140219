import { makeStyles, shorthands } from "@fluentui/react-components";
import { getRandomVibrantColorHex } from "../utils/HelperFunctions";

export const useStylesUserInitials = makeStyles({
  circle: {
    marginBottom: "3px",
    display: "inline-flex",
    marginRight: "10px",
    width: "30px",
    height: "30px",
    ...shorthands.borderRadius("50%"),
    verticalAlign: "middle",
  },
  text: {
    marginBottom: "3px",
    ...shorthands.margin("auto"),
    fontWeight: "400",
    color: "white",
    fontSize: "11px",
  },
});
