/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KS_customerservice_Service_Models_B2CCompanyData } from '../models/KS_customerservice_Service_Models_B2CCompanyData';
import type { KS_customerservice_Service_Models_FilterDto } from '../models/KS_customerservice_Service_Models_FilterDto';
import type { KS_customerservice_Service_Models_UserDto } from '../models/KS_customerservice_Service_Models_UserDto';
import type { KS_customerservice_Service_Models_UserManagerUserDto } from '../models/KS_customerservice_Service_Models_UserManagerUserDto';
import type { Microsoft_Graph_Models_User } from '../models/Microsoft_Graph_Models_User';
import type { Microsoft_Graph_Models_UserCollectionResponse } from '../models/Microsoft_Graph_Models_UserCollectionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerServiceService {

    /**
     * @param requestBody 
     * @returns Microsoft_Graph_Models_UserCollectionResponse Success
     * @throws ApiError
     */
    public static postApiCustomerServiceFilter(
requestBody?: KS_customerservice_Service_Models_FilterDto,
): CancelablePromise<Microsoft_Graph_Models_UserCollectionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/CustomerService/filter',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns KS_customerservice_Service_Models_UserDto Success
     * @throws ApiError
     */
    public static getApiCustomerServiceUsers(): CancelablePromise<Array<KS_customerservice_Service_Models_UserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/CustomerService/users',
        });
    }

    /**
     * @param id 
     * @returns Microsoft_Graph_Models_User Success
     * @throws ApiError
     */
    public static getApiCustomerServiceUser(
id?: string,
): CancelablePromise<Microsoft_Graph_Models_User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/CustomerService/user',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param oid 
     * @returns KS_customerservice_Service_Models_B2CCompanyData Success
     * @throws ApiError
     */
    public static getApiCustomerServiceUsermanagerCompanies(
oid?: string,
): CancelablePromise<Array<KS_customerservice_Service_Models_B2CCompanyData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/CustomerService/usermanager-companies',
            query: {
                'oid': oid,
            },
        });
    }

    /**
     * @param guid 
     * @returns KS_customerservice_Service_Models_UserManagerUserDto Success
     * @throws ApiError
     */
    public static getApiCustomerServiceUsermanagerUsers(
guid?: string,
): CancelablePromise<Array<KS_customerservice_Service_Models_UserManagerUserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/CustomerService/usermanager-users',
            query: {
                'guid': guid,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiCustomerService(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/CustomerService',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static patchApiCustomerService(
requestBody?: Microsoft_Graph_Models_User,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/CustomerService',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
