import { useEffect, useState } from "react";
import { UserInitialsProps } from "../../interfaces/UserInitialsProps";
import { useStylesUserInitials } from "../../styles/UserInitialsStyle";
import {
  getInitialsFromName,
  getRandomVibrantColorHex,
} from "../../utils/HelperFunctions";

const UserInitials: React.FC<UserInitialsProps> = ({ name }) => {
  const styles = useStylesUserInitials();
  const [color, setColor] = useState("#ffffff");
  const [initials, setInitials] = useState("");

  useEffect(() => {
    setInitials(getInitialsFromName(name));
    setColor(getRandomVibrantColorHex());
  }, []);

  return (
    <span className={styles.circle} style={{ backgroundColor: color }}>
      <span className={styles.text}>{initials}</span>
    </span>
  );
};

export default UserInitials;
