import {
  Button,
  createTableColumn,
  TableCellLayout,
  TableColumnDefinition,
  Theme,
} from "@fluentui/react-components";
import UserInitials from "../user-initials/UserInitials";
import {
  KS_customerservice_Service_Models_B2CCompanyData,
  Microsoft_Graph_Models_User,
} from "../../CustomerSvcService";
import { formatDate } from "../../utils/HelperFunctions";
import { ListIconMedium } from "../../utils/Icons";

export const columnSizingOptionsCompanyData = {
  companyName: {
    minWidth: 100,
    defaultWidth: 350,
  },
  id: {
    minWidth: 100,
    defaultWidth: 300,
  },
  guid: {
    minWidth: 100,
    defaultWidth: 300,
  },
  role: {
    minWidth: 100,
    defaultWidth: 100,
  },
};

export const getColumnsCompanyData = (
  theme: Theme,
  handleMoreClicked: ((guid: string | null | undefined) => void) | undefined
) =>
  [
    createTableColumn<KS_customerservice_Service_Models_B2CCompanyData>({
      columnId: "companyName",
      renderHeaderCell: () => "Cégnév",
      renderCell: (item: KS_customerservice_Service_Models_B2CCompanyData) => {
        return (
          <TableCellLayout truncate>{item.companyName ?? ""}</TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_B2CCompanyData>),
    createTableColumn<KS_customerservice_Service_Models_B2CCompanyData>({
      columnId: "id",
      renderHeaderCell: () => "Id",
      renderCell: (item: KS_customerservice_Service_Models_B2CCompanyData) => {
        return <TableCellLayout truncate>{item.id ?? ""}</TableCellLayout>;
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_B2CCompanyData>),
    createTableColumn<KS_customerservice_Service_Models_B2CCompanyData>({
      columnId: "guid",
      renderHeaderCell: () => "Guid",
      renderCell: (item: KS_customerservice_Service_Models_B2CCompanyData) => {
        return <TableCellLayout truncate>{item.guid ?? ""}</TableCellLayout>;
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_B2CCompanyData>),
    createTableColumn<KS_customerservice_Service_Models_B2CCompanyData>({
      columnId: "role",
      renderHeaderCell: () => "Szerepkör",
      renderCell: (item: KS_customerservice_Service_Models_B2CCompanyData) => {
        return <TableCellLayout truncate>{item.role ?? ""}</TableCellLayout>;
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_B2CCompanyData>),
    createTableColumn<KS_customerservice_Service_Models_B2CCompanyData>({
      columnId: "users",
      renderHeaderCell: () => "Guid-hoz tartozó felhasználók",
      renderCell: (item: KS_customerservice_Service_Models_B2CCompanyData) => {
        return (
          <TableCellLayout truncate>
            <ListIconMedium
              onClick={() => handleMoreClicked && handleMoreClicked(item.guid)}
            />
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_B2CCompanyData>),
  ] as TableColumnDefinition<KS_customerservice_Service_Models_B2CCompanyData>[];
