import {
  createTableColumn,
  TableCellLayout,
  TableColumnDefinition,
  Theme,
  Tooltip,
} from "@fluentui/react-components";
import {
  KS_customerservice_Service_Data_Enums_DataPointInvitationStatus,
  KS_customerservice_Service_Models_AdminUserDtos_InvitationDto,
} from "../../CustomerSvcService";
import { formatDate, getEnumTypeText } from "../../utils/HelperFunctions";
import { ListIconMedium } from "../../utils/Icons";
import { NavigateFunction } from "react-router-dom";
import { dateFormatOptions } from "../../utils/GlobalConsts";

export const columnSizingOptionsAdatpontInvitations = {
  from: {
    minWidth: 100,
    defaultWidth: 300,
  },
  to: {
    minWidth: 100,
    defaultWidth: 300,
  },
  status: {
    minWidth: 100,
    defaultWidth: 150,
  },
  id: {
    minWidth: 100,
    defaultWidth: 300,
  },
  createdAt: {
    minWidth: 100,
    defaultWidth: 100,
  },
};

const getStatus = (
  statusEnum?: KS_customerservice_Service_Data_Enums_DataPointInvitationStatus
) => {
  switch (statusEnum) {
    case KS_customerservice_Service_Data_Enums_DataPointInvitationStatus._0:
      return "Függőben";
    case KS_customerservice_Service_Data_Enums_DataPointInvitationStatus._1:
      return "Elfogadva";
    default:
      return "-";
  }
};

export const getColumnsAdatpontInvitations = (theme: Theme) =>
  [
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>(
      {
        columnId: "from",
        renderHeaderCell: () => "Meghívó (object id)",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_InvitationDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip content={item.from ?? ""} relationship={"description"}>
                <span>{item.from ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>(
      {
        columnId: "to",
        renderHeaderCell: () => "Email-cím",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_InvitationDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip content={item.to ?? ""} relationship={"description"}>
                <span>{item.to ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>(
      {
        columnId: "status",
        renderHeaderCell: () => "Státusz",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_InvitationDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip content={item.status ?? ""} relationship={"description"}>
                <span>{getStatus(item.status)}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>(
      {
        columnId: "id",
        renderHeaderCell: () => "User (object id)",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_InvitationDto
        ) => {
          return (
            <TableCellLayout truncate>
              <Tooltip
                content={"Ha elfogadta a meghívást és létrejött a B2C user"}
                relationship={"description"}
              >
                <span>{item.id ?? ""}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>
    ),
    createTableColumn<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>(
      {
        columnId: "creratedAt",
        renderHeaderCell: () => "Létrehozás",
        renderCell: (
          item: KS_customerservice_Service_Models_AdminUserDtos_InvitationDto
        ) => {
          const date = item.createdAt
            ? new Date(item.createdAt).toLocaleDateString(
                "hu-HU",
                dateFormatOptions
              )
            : "-";
          return (
            <TableCellLayout truncate>
              <Tooltip content={date} relationship={"description"}>
                <span>{date}</span>
              </Tooltip>
            </TableCellLayout>
          );
        },
      } as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>
    ),
  ] as TableColumnDefinition<KS_customerservice_Service_Models_AdminUserDtos_InvitationDto>[];
