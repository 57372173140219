import { Spinner, Toast, ToastTitle } from "@fluentui/react-components";
import { TOAST } from "./Enums";

export const notify = (intent: TOAST, text: string, dispatchToast: any) => {
  switch (intent) {
    case "progress":
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle media={<Spinner size="tiny" />}>{text}</ToastTitle>
        </Toast>
      );
      break;
    case "error":
    case "info":
    case "success":
    case "warning":
      dispatchToast(
        <Toast appearance="inverted">
          <ToastTitle>{text}</ToastTitle>
        </Toast>,
        { intent }
      );
      break;
  }
};
