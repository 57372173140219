import {
  BrandVariants,
  Theme,
  createLightTheme,
  createDarkTheme,
} from "@fluentui/react-components";

export const blueTheme: BrandVariants = {
  10: "#010c1a",
  20: "#021c3d",
  30: "#251D55",
  40: "#032f66",
  50: "#033f8a",
  60: "#034eab",
  70: "#0457bf",
  80: "#165EDC",
  90: "#076EF1",
  100: "#007AFF",
  110: "#458DFF",
  120: "#619DFF",
  130: "#79ACFF",
  140: "#91BCFF",
  150: "#A8CBFF",
  160: "#BFDAFF",
};

export const lightTheme: Theme = {
  ...createLightTheme(blueTheme),
  fontFamilyBase: "Space Grotesk",
};

export const darkTheme: Theme = {
  ...createDarkTheme(blueTheme),
  fontFamilyBase: "Space Grotesk",
};
