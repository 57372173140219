import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
} from "@fluentui/react-components";
import DialogActionsComponent from "./DialogActionsComponent";
import { DialogSkeletonProps } from "../../interfaces/DialogSkeletonProps";

const DialogSkeleton: React.FC<DialogSkeletonProps> = ({
  open,
  setOpen,
  title,
  closeBtnText,
  okBtnText: saveBtnText,
  saveAction,
  handleDialogOpenStateChange,
  isModalLoading,
  disabledSave,
  children,
}) => {
  const handleStateChange = (event: any, data: any) => {
    if (handleDialogOpenStateChange !== undefined)
      handleDialogOpenStateChange(data.open);
    else setOpen(data.open);
  };
  return (
    <Dialog open={open} onOpenChange={handleStateChange}>
      <DialogSurface style={{ maxWidth: "500px" }}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActionsComponent
            closeBtnText={closeBtnText}
            saveBtnText={saveBtnText}
            saveAction={saveAction}
            isModalLoading={isModalLoading}
            disabledSave={disabledSave}
          />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DialogSkeleton;
