import * as React from "react";
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  useToastController,
} from "@fluentui/react-components";
import { useStylesTable } from "../../styles/TableStyle";
import { TableProps } from "../../interfaces/TableProps";
import { columnSizingOptionsUsers, getColumnsUsers } from "./TableUsersColumns";
import ClipLoader from "react-spinners/ClipLoader";
import { ThemeContext, ToasterContext } from "../../utils/GlobalContexts";
import { useContext, useState } from "react";
import { Microsoft_Graph_Models_User } from "../../CustomerSvcService";
import { TABLE } from "../../utils/Enums";
import {
  columnSizingOptionsCompanyData,
  getColumnsCompanyData as getColumnsUserManagerCompanyData,
} from "./TableUserManagerCompanyDataColumns";
import {
  columnSizingOptionsAdatpontTenants,
  getColumnsAdatpontTenants,
} from "./TableAdatPontTenantsColumns";
import {
  columnSizingOptionsUserManagerUsers,
  getColumnsUserManagerUsers,
} from "./TableUserManagerUsersColumns";
import { useNavigate } from "react-router-dom";
import {
  columnSizingOptionsAdatpontCompanies,
  getColumnsAdatpontCompanies,
} from "./TableAdatPontCompaniesColumns";
import {
  columnSizingOptionsAdatpontInvitations,
  getColumnsAdatpontInvitations,
} from "./TableAdatPontInvitationsColumns";
import {
  columnSizingOptionsAdatpontUsers,
  getColumnsAdatpontUsers,
} from "./TableAdatPontUsersColumns";

const TableComponent: React.FC<TableProps> = ({
  data,
  selectedDto,
  setSelectedDto,
  isLoading,
  isLoadingMoreData,
  showMore,
  nextLink,
  handleRowAction,
  type,
  oid,
}) => {
  const { theme } = useContext(ThemeContext);
  const styles = useStylesTable();
  const [pageCount, setPageCount] = React.useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();

  const columns =
    type === TABLE.USERS
      ? getColumnsUsers(theme, handleRowAction, navigate)
      : type === TABLE.USERMANAGER_COMPANYDATA
      ? getColumnsUserManagerCompanyData(theme, handleRowAction)
      : type === TABLE.ADATPONT_TENANTS
      ? getColumnsAdatpontTenants(theme, navigate, oid ?? "")
      : type === TABLE.ADATPONT_INVITATIONS
      ? getColumnsAdatpontInvitations(theme)
      : type === TABLE.ADATPONT_USERS
      ? getColumnsAdatpontUsers(theme)
      : type === TABLE.ADATPONT_COMPANIES
      ? getColumnsAdatpontCompanies(theme)
      : getColumnsUserManagerUsers(theme);

  React.useEffect(() => {
    setPageCount(Math.ceil((data?.length ?? 0) / pageSize));
  }, [data]);

  const handleRowClick = (e: any, item: any) => {
    if (type !== TABLE.USERS) return;
    setSelectedDto && setSelectedDto(item);
  };

  const refMap = React.useRef<Record<string, HTMLElement | null>>({});

  const getRowColor = (item: any) => {
    if (type !== TABLE.USERS) return styles.row;
    if (selectedDto?.id === (item as Microsoft_Graph_Models_User).id)
      return styles.selectedRow;
    else return styles.row;
  };

  function getPageData() {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return data?.slice(startIndex, endIndex);
  }

  return isLoading ? (
    <div
      style={{
        textAlign: "center",
        margin: "20px",
      }}
    >
      <ClipLoader size={60} color={theme.colorBrandForeground1} />
    </div>
  ) : data?.length === 0 || !data ? (
    <div
      style={{
        textAlign: "center",
        margin: "20px",
      }}
    >
      <div>Nincsenek megjeleníthető adatok</div>
    </div>
  ) : (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "calc(100vh - 250px)",
      }}
    >
      <DataGrid
        size="small"
        columns={columns}
        items={data ? data : []}
        sortable
        resizableColumns
        getRowId={(item) => item.rowKey}
        style={{
          borderCollapse: "collapse",
        }}
        columnSizingOptions={
          type === TABLE.USERS
            ? columnSizingOptionsUsers
            : type === TABLE.USERMANAGER_COMPANYDATA
            ? columnSizingOptionsCompanyData
            : type === TABLE.ADATPONT_TENANTS
            ? columnSizingOptionsAdatpontTenants
            : type === TABLE.ADATPONT_COMPANIES
            ? columnSizingOptionsAdatpontCompanies
            : type === TABLE.ADATPONT_INVITATIONS
            ? columnSizingOptionsAdatpontInvitations
            : type === TABLE.ADATPONT_USERS
            ? columnSizingOptionsAdatpontUsers
            : columnSizingOptionsUserManagerUsers
        }
      >
        <DataGridHeader
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 1000,
          }}
        >
          <DataGridRow
            id="table-header"
            className={styles.tableHeader}
            style={{ backgroundColor: theme.colorBrandForeground1 }}
          >
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <DataGridHeaderCell
                  ref={(el) => (refMap.current[columnId] = el)}
                  className={styles.headerCell}
                >
                  {renderHeaderCell()}
                </DataGridHeaderCell>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>

        <DataGridBody>
          {({ item, rowId }) => (
            <DataGridRow
              key={rowId}
              className={getRowColor(item)}
              onClick={(e: any) => handleRowClick(e, item as any)}
            >
              {({ renderCell }) => (
                <DataGridCell className={styles.bodyCell}>
                  {renderCell(item)}
                </DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      <div
        style={{
          position: "absolute",
          bottom: 40,
          width: "100%",
          margin: "auto",
          textAlign: "center",
        }}
      >
        {isLoadingMoreData ? (
          <ClipLoader size={60} color={theme.colorBrandForeground1} />
        ) : nextLink ? (
          <span
            style={{
              cursor: "pointer",
              color: theme.colorBrandForeground1,
              fontWeight: 700,
            }}
            onClick={showMore}
          >
            Több betöltése
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TableComponent;
