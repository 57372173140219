import { useContext, useEffect, useState } from "react";
import { useStylesMainPage } from "../styles/MainPageStyle";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AdminUserService,
  KS_customerservice_Service_Models_AdminUserDtos_CompanyDto,
} from "../CustomerSvcService";
import TableComponent from "../components/table/TableComponent";
import { TABLE, TOAST } from "../utils/Enums";
import { Button, useToastController } from "@fluentui/react-components";
import { notify } from "../utils/DispatchToaster";
import { ToasterContext } from "../utils/GlobalContexts";

export default function CompaniesByTenantPage() {
  const styles = useStylesMainPage();
  const location = useLocation();
  const oid = location.state.oid;
  const tenant = location.state.tenant;
  const [companies, setCompanies] = useState<
    KS_customerservice_Service_Models_AdminUserDtos_CompanyDto[] | null
  >();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { toasterId } = useContext(ToasterContext);
  const { dispatchToast } = useToastController(toasterId);

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (oid) getData();
  }, [oid]);

  const getData = async () => {
    if (oid) setIsLoading(true);
    await AdminUserService.getApiAdminUser(oid)
      .then((res) => {
        if (res) {
          setCompanies(res.tenants?.find((t) => t.id === tenant)?.companies);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        notify(TOAST.ERROR, "Nem sikerült az adatok betöltése", dispatchToast);
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.main}>
      <div className={styles.backButton}>
        <Button onClick={navigateBack}>Vissza</Button>
        <div
          className={styles.pageText}
        >{`${tenant} tenanthoz tartozó cégek`}</div>
      </div>
      <TableComponent
        type={TABLE.ADATPONT_COMPANIES}
        data={companies}
        isLoading={isLoading}
      />
    </div>
  );
}
