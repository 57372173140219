import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStylesDialog = makeStyles({
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "'Space Grotesk', sans-serif",
    display: "block",
    textAlign: "center",
  },
  companyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "50vh",
  },
  company: {
    marginBottom: "10px",
    ...shorthands.padding("7px", "15px"),
    ...shorthands.borderRadius("10px"),
  },
  companyName: { fontWeight: 700, marginBottom: "5px", fontSize: "15px" },
});
