import { useEffect, useState } from "react";
import { useStylesMainPage } from "../styles/MainPageStyle";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomerServiceService,
  KS_customerservice_Service_Models_B2CCompanyData,
} from "../CustomerSvcService";
import TableComponent from "../components/table/TableComponent";
import { NOTIFICATION_DIALOG, TABLE } from "../utils/Enums";
import DialogComponent from "../components/dialog/DialogComponent";
import { useStylesDialog } from "../styles/FormDialogStyle";
import { ReactComponent as CompleteSVG } from "../assets/complete.svg";
import { ReactComponent as WarningSVG } from "../assets/warning.svg";
import { ReactComponent as UnsuccessfulSVG } from "../assets/unsuccesful.svg";
import { Button } from "@fluentui/react-components";

export default function CompanyDataPage() {
  const styles = useStylesMainPage();
  const stylesDialog = useStylesDialog();
  const { id } = useParams();
  const [userCompanies, setUserCompanies] = useState<
    null | KS_customerservice_Service_Models_B2CCompanyData[]
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const [notificationDialogText, setNotificationDialogText] = useState("");
  const [notificationDialogTitle, setNotificationDialogTitle] = useState("");
  const [notificationDialogType, setNotificationDialogType] = useState(
    NOTIFICATION_DIALOG.WARNING
  );
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (id) getCompanyData();
  }, [id]);

  const getCompanyData = async () => {
    await CustomerServiceService.getApiCustomerServiceUsermanagerCompanies(id)
      .then((res) => {
        if (res && res[0]) {
          setUserCompanies(res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleNotificationDialogOpen = (
    title: string,
    text: string,
    type: NOTIFICATION_DIALOG
  ) => {
    setNotificationDialogText(text);
    setNotificationDialogTitle(title);
    setNotificationDialogType(type);
    setOpenNotificationDialog(true);
  };

  const handleUsersByGuidClicked = async (guid: string | null | undefined) => {
    if (guid)
      await CustomerServiceService.getApiCustomerServiceUsermanagerUsers(guid)
        .then((res) => {
          if (res && res[0]) {
            const uri = `/users-by-guid/${guid}`;
            // window?.open(uri, "_blank")?.focus();
            navigate(uri);
          } else
            handleNotificationDialogOpen(
              `Figyelmeztetés`,
              "Az adatbázishoz nem tartoznak megjeleníthető felhasználók.",
              NOTIFICATION_DIALOG.WARNING
            );
        })
        .catch((e) => {
          handleNotificationDialogOpen(
            `Hiba`,
            "Az adatbázishoz tartozó felhasználók betöltése nem sikerült.",
            NOTIFICATION_DIALOG.FAIL
          );
        });
  };

  return (
    <div className={styles.main}>
      <div className={styles.backButton}>
        <Button onClick={navigateBack}>Vissza</Button>
        <div
          className={styles.pageText}
        >{`${id} id-hoz tartozó adatbázisok`}</div>
      </div>
      <TableComponent
        handleRowAction={handleUsersByGuidClicked}
        type={TABLE.USERMANAGER_COMPANYDATA}
        data={userCompanies}
        isLoading={isLoading}
      />
      <DialogComponent
        open={openNotificationDialog}
        setOpen={setOpenNotificationDialog}
        handleClose={() => setOpenNotificationDialog(false)}
        closeBtnText="Ok"
        title={notificationDialogTitle}
      >
        <div style={{ marginBottom: "10px" }}>
          {notificationDialogType === NOTIFICATION_DIALOG.SUCCESS ? (
            <CompleteSVG
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            />
          ) : notificationDialogType === NOTIFICATION_DIALOG.FAIL ? (
            <UnsuccessfulSVG
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            />
          ) : (
            <WarningSVG
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                marginBottom: "10px",
              }}
            />
          )}
          <div className={stylesDialog.text}>{notificationDialogText}</div>
        </div>
      </DialogComponent>
    </div>
  );
}
