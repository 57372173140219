import {
  Button,
  createTableColumn,
  TableCellLayout,
  TableColumnDefinition,
  Theme,
} from "@fluentui/react-components";
import UserInitials from "../user-initials/UserInitials";
import { KS_customerservice_Service_Models_UserDto } from "../../CustomerSvcService";
import { formatDate } from "../../utils/HelperFunctions";
import { ListIconMedium } from "../../utils/Icons";
import { ReactComponent as AdatpontLogo } from "../../assets/adatpont.svg";
import { NavigateFunction } from "react-router-dom";
import { INTERNAL_ROUTE } from "../../utils/Enums";

export const columnSizingOptionsUsers = {
  displayName: {
    minWidth: 30,
    defaultWidth: 350,
  },
  name: {
    minWidth: 30,
    defaultWidth: 230,
  },
  userName: {
    minWidth: 30,
    defaultWidth: 230,
  },
  identity: {
    minWidth: 30,
    defaultWidth: 150,
  },
  userType: {
    minWidth: 30,
    defaultWidth: 80,
  },
  accountEnabled: {
    minWidth: 30,
    defaultWidth: 60,
  },
  createdDateTime: {
    minWidth: 110,
    defaultWidth: 60,
  },
  companies: {
    minWidth: 30,
  },
};

export const getColumnsUsers = (
  theme: Theme,
  handleOpenCompaniesDialog:
    | ((oid: string | null | undefined) => void)
    | undefined,
  navigate: NavigateFunction
) =>
  [
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "displayName",
      renderHeaderCell: () => "Megjelenített név",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return (
          <TableCellLayout truncate>
            <div style={{ margin: "3px 0px" }}>
              <UserInitials name={item.displayName ?? ""} />
              <span
                className="link"
                style={{ color: theme.colorBrandForeground2 }}
              >
                {item.displayName ?? ""}
              </span>
            </div>
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "name",
      renderHeaderCell: () => "Név",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return <TableCellLayout truncate>{item.name}</TableCellLayout>;
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "userName",
      renderHeaderCell: () => "Felhasználónév",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return <TableCellLayout truncate>{item.userName}</TableCellLayout>;
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "identity",
      renderHeaderCell: () => "Identities",
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>
            <span>{item.identity}</span>
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "userType",
      renderHeaderCell: () => "Típus",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return (
          <TableCellLayout truncate>{item.userType ?? ""}</TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "accountEnabled",
      renderHeaderCell: () => "Enabled",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return (
          <TableCellLayout truncate>
            {item.accountEnabled ? <span>✅</span> : <span>❌</span>}
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "createdDateTime",
      renderHeaderCell: () => "Létrehozás",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return (
          <TableCellLayout truncate>
            {formatDate(item.createdDateTime)}
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "companies",
      renderHeaderCell: () => "KÜ cégek",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return (
          <TableCellLayout truncate>
            <ListIconMedium
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleOpenCompaniesDialog && handleOpenCompaniesDialog(item.id)
              }
            />
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
    createTableColumn<KS_customerservice_Service_Models_UserDto>({
      columnId: "datapoint",
      renderHeaderCell: () => "Adatpont info",
      renderCell: (item: KS_customerservice_Service_Models_UserDto) => {
        return (
          <TableCellLayout truncate>
            <AdatpontLogo
              style={{ cursor: "pointer", width: "24px", height: "24px" }}
              onClick={() => {
                item.id &&
                  navigate("/adatpont-tenants", {
                    state: {
                      id: item.id,
                    },
                  });
              }}
            />
          </TableCellLayout>
        );
      },
    } as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>),
  ] as TableColumnDefinition<KS_customerservice_Service_Models_UserDto>[];
