import * as React from "react";
import DialogSkeleton from "./DialogSkeleton";

import { DialogProps } from "../../interfaces/DialogFormProps";

const DialogComponent: React.FC<DialogProps> = ({
  open,
  setOpen,
  handleSave,
  handleClose,
  okBtnText,
  closeBtnText,
  title,
  isModalLoading,
  disabledSave,
  children,
}) => {
  return (
    <DialogSkeleton
      open={open}
      setOpen={setOpen}
      title={title}
      saveAction={handleSave}
      okBtnText={okBtnText}
      closeBtnText={closeBtnText}
      handleDialogOpenStateChange={handleClose}
      isModalLoading={isModalLoading}
      disabledSave={disabledSave}
    >
      {children}
    </DialogSkeleton>
  );
};

export default DialogComponent;
