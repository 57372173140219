export enum INTERNAL_ROUTE {
  START = "/",
}

export enum LOCAL_STORAGE_KEY {
  MS_AUTH_TOKEN = "msAuthToken",
}

export enum TOAST {
  WARN = "warning",
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  PROGRESS = "progress",
}

export enum NOTIFICATION_DIALOG {
  SUCCESS,
  FAIL,
  WARNING,
}

export enum TABLE {
  USERS,
  USERMANAGER_COMPANYDATA,
  USERMANAGER_USER,
  ADATPONT_TENANTS,
  ADATPONT_COMPANIES,
  ADATPONT_USERS,
  ADATPONT_INVITATIONS,
}
