/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { KS_customerservice_Service_Models_AdminUserDtos_AdminUserDto } from '../models/KS_customerservice_Service_Models_AdminUserDtos_AdminUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminUserService {

    /**
     * @param oid 
     * @returns KS_customerservice_Service_Models_AdminUserDtos_AdminUserDto Success
     * @throws ApiError
     */
    public static getApiAdminUser(
oid: string,
): CancelablePromise<KS_customerservice_Service_Models_AdminUserDtos_AdminUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/AdminUser/{oid}',
            path: {
                'oid': oid,
            },
        });
    }

}
