import { Theme } from "@fluentui/react-components";
import React from "react";
import { lightTheme } from "./FluentThemeConstructor";

export type RefreshProps = {
  refresh: boolean;
  setRefresh: any;
};

export const RefreshContext = React.createContext<RefreshProps>({
  refresh: false,
  setRefresh: null,
});

export type DeviceProps = {
  isMobile: boolean;
  setIsMobile: any;
};

export const DeviceContext = React.createContext<DeviceProps>({
  isMobile: false,
  setIsMobile: null,
});

export const ToasterContext = React.createContext<ToasterProps>({
  toasterId: "",
});

export type ToasterProps = {
  toasterId: string;
};

export type ThemeProps = {
  theme: Theme;
  setTheme: any;
};

export const ThemeContext = React.createContext<ThemeProps>({
  theme: lightTheme,
  setTheme: null,
});
