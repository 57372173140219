import axios from "axios";
import { FilterObj } from "../models/FilterObj";
import {
  CustomerServiceService,
  KS_customerservice_Service_Data_Enums_InvitationStatus,
  KS_customerservice_Service_Data_Enums_Role,
  Microsoft_Graph_Models_User,
} from "../CustomerSvcService";

export const formatDate = (date: string | undefined | null): string => {
  if (date) {
    const formattedDate = new Date(date);

    const year = formattedDate.getFullYear();
    const month = ("0" + (formattedDate.getMonth() + 1)).slice(-2);
    const day = ("0" + formattedDate.getDate()).slice(-2);
    const hours = ("0" + formattedDate.getHours()).slice(-2);
    const minutes = ("0" + formattedDate.getMinutes()).slice(-2);

    return `${year}.${month}.${day}. ${hours}:${minutes}`;
  }
  return "";
};

export const trimLongString = (
  string: string | null | undefined,
  length: number
) =>
  string && string?.length > length
    ? string.substring(0, length - 3) + "..."
    : string;

export async function retrieveProfilePhoto(token: string | void) {
  try {
    const response = await axios.get(
      "https://graph.microsoft.com/v1.0/me/photo/$value",
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error retrieving profile photo:", error);
    return null;
  }
}

export async function retrieveProfileInfo(token: string | void) {
  try {
    const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving profile photo:", error);
    return null;
  }
}

export const getInitialsFromName = (name: string) => {
  const words = name.split(" ");
  let initials = "";

  if (words.length === 1) {
    initials = words[0].slice(0, 2).toUpperCase();
  } else {
    for (let i = 0; i < words.length && initials.length < 2; i++) {
      initials += words[i][0] ?? "";
    }
    initials = initials.toUpperCase();
  }

  return initials;
};

export function getRandomVibrantColorHex(): string {
  const randomHue: number = Math.floor(Math.random() * 360);
  const baseSaturation: number = 50;
  const baseLightness: number = 50;
  const randomSaturation: number =
    Math.floor(Math.random() * 30) + baseSaturation;
  const randomLightness: number =
    Math.floor(Math.random() * 30) + baseLightness;
  const randomColor: string = `hsl(${randomHue},${randomSaturation}%,${randomLightness}%)`;
  const rgbColor: number[] = hslToRgb(randomColor);
  const hexColor: string = rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);
  return hexColor;
}

function hslToRgb(hsl: string): number[] {
  let [h, s, l]: number[] = hsl.match(/\d+/g)!.map(Number);
  h /= 360;
  s /= 100;
  l /= 100;
  let r: number, g: number, b: number;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hueToRgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q: number = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p: number = 2 * l - q;
    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function rgbToHex(r: number, g: number, b: number): string {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export const isUserUnchanged = (
  og: Microsoft_Graph_Models_User | null,
  unsaved: Microsoft_Graph_Models_User | null
) => {
  return (
    og === null ||
    unsaved === null ||
    (og.displayName === unsaved.displayName &&
      og.surname === unsaved.surname &&
      og.accountEnabled === unsaved.accountEnabled &&
      og.givenName === unsaved.givenName)
  );
};

export const getEnumTypeText = (enumType: any) => {
  switch (enumType) {
    case KS_customerservice_Service_Data_Enums_Role._0:
    case "Admin":
      return "Admin";
    case KS_customerservice_Service_Data_Enums_Role._1:
    case "Accountant":
      return "Könyvelő";
    case KS_customerservice_Service_Data_Enums_Role._2:
    case "User":
      return "Felhasználó";
    case KS_customerservice_Service_Data_Enums_Role._3:
    case "Guest":
      return "Vendég";
    case KS_customerservice_Service_Data_Enums_InvitationStatus._2:
    case "ADDED_WITH_PASSWORD":
      return "Jelszóval összekötött";
    case KS_customerservice_Service_Data_Enums_InvitationStatus._0:
    case "PENDING":
      return "Függőben";
    case KS_customerservice_Service_Data_Enums_InvitationStatus._1:
    case "DONE":
      return "Elfogadott";
    default:
      return "";
  }
};
