import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./auth";
import DeviceCheck from "./utils/device-check/DeviceCheck";
import UserListPage from "./pages/UserListPage";
import CompanyDataPage from "./pages/CompanyDataPage";
import UsersByDbPage from "./pages/UsersByDbPage";
import TenantByUserPage from "./pages/TenantByUserPage";
import UsersByTenantPage from "./pages/UsersByTenantPage";
import InvitationsByTenantPage from "./pages/InvitationsByTenantPage";
import CompaniesByTenantPage from "./pages/CompaniesByTenantPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Auth>
              <UserListPage />
            </Auth>
          }
        />
        <Route
          path="/company-data/:id"
          element={
            <Auth>
              <CompanyDataPage />
            </Auth>
          }
        />

        <Route
          path="/users-by-guid/:id"
          element={
            <Auth>
              <UsersByDbPage />
            </Auth>
          }
        />
        <Route
          path="/adatpont-tenants"
          element={
            <Auth>
              <TenantByUserPage />
            </Auth>
          }
        />
        <Route
          path="/adatpont-users"
          element={
            <Auth>
              <UsersByTenantPage />
            </Auth>
          }
        />
        <Route
          path="/adatpont-invitations"
          element={
            <Auth>
              <InvitationsByTenantPage />
            </Auth>
          }
        />
        <Route
          path="/adatpont-companies"
          element={
            <Auth>
              <CompaniesByTenantPage />
            </Auth>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
