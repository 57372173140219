import { useStylesFooter } from "../../styles/FooterStyle";
import { ReactComponent as KsLogo } from "../../assets/ks-logo-text.svg";

const Footer = () => {
  const styles = useStylesFooter();
  return (
    <footer className={`${styles.footer} ${styles.mainPagePosition}`}>
      <KsLogo style={{ height: "50px" }} />
      <span className={styles.text}>© 2023 Kulcs-Soft Nyrt.</span>
    </footer>
  );
};

export default Footer;
