export const DayPickerStrings = {
  months: [
    "Január",
    "Február",
    "Március",
    "Április",
    "Május",
    "Június",
    "Július",
    "Augusztus",
    "Szeptember",
    "Október",
    "November",
    "December",
  ],

  shortMonths: [
    "Jan",
    "Feb",
    "Márc",
    "Ápr",
    "Máj",
    "Jún",
    "Júl",
    "Aug",
    "Szep",
    "Okt",
    "Nov",
    "Dec",
  ],

  days: [
    "Vasárnap",
    "Hétfő",
    "Kedd",
    "Szerda",
    "Csütörtök",
    "Péntek",
    "Szombat",
  ],

  shortDays: ["V", "H", "K", "SZ", "CS", "P", "SZ"],

  goToToday: "Ugrás a mai napra",
  prevMonthAriaLabel: "Előző hónap",
  nextMonthAriaLabel: "Következő hónap",
  prevYearAriaLabel: "Előző év",
  nextYearAriaLabel: "Következő év",
};

export const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};
